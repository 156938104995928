<template lang="pug">
.main-content.kyc-no-confirmed-message.layout-main__centering.container.full-height(
  v-if="mustGoThroughKyc"
  v-loading="isProfileLoading"
)
  KycNoVerificationMessage
.main-content.create-offer.layout-main__centering.container.pb-5(v-else)
  .create-offer__step-bar
    span.tablinks(:class="{'active': $route.params.step === 'insert'}") 1. Создание
    span.tablinks(:class="{'active': $route.params.step === 'confirm'}") 2. Подтверждение
    span.tablinks(:class="{'active': $route.params.step === 'done'}") 3. Завершение
  insert-data(v-if="$route.params.step === 'insert'", :form='form', :isChangeAd='isChangeAd', @next='confirm', @change="changeAd")
  confirm-data(v-if="$route.params.step === 'confirm'", :form='form', :isChangeAd='isChangeAd', @confirm='publish')
  done(v-if="$route.params.step === 'done'", :form='form', :isChangeAd='isChangeAd', @next='reset')
</template>

<script>
  import { mapGetters } from 'vuex';
  import { USER_PROFILE } from '../store/actions/user';
  import InsertData from '../components/AdCreate/InsertData.vue';
  import ConfirmData from '../components/AdCreate/ConfirmData.vue';
  import Done from '../components/AdCreate/Done.vue';
  import KycNoVerificationMessage from '../components/AwesomeComponents/Account/Profile/KycNoVerificationMessage.vue';

  export default {
    components: {
      InsertData,
      ConfirmData,
      Done,
      KycNoVerificationMessage,
    },

    data() {
      return {
        isChangeAd: false,
        form: this.defaultValues(),
      }
    },

    computed: {
      ...mapGetters({
        loading: 'loading',
        profile: 'getProfile',
      }),
      isProfileLoading() {
        return this.loading(USER_PROFILE) === 'loading';
      },
      mustGoThroughKyc() {
        return this.profile.is_kyc_required && !this.profile.is_kyc_confirmed;
      },
    },

    created() {
      this.$router.push({name: 'AdCreate', params: {step: 'insert'} }).catch(()=>{});
      if (this.profile.totalBalanceInUSD === 0) {
        this.form.is_sale = false;
      }
    },

    methods: {
      defaultValues() {
        return {
          banks: [],
          is_sale: true,
          currency_id: 1,
          crypto_currency_id: 1,
          country_id: 1,
          payment_system_id: 1,
          payment_requisites: [],
          conditions: '',
          price: '',
          min: '',
          max: '',
          userquility: 0,
          turnover: null,
          min_deal_finished_count: null,
          deal_cancellation_max_percent: null,
          time: null,
          review_rate: null,
          is_active: true,
          liquidity_required: false,
          email_confirm_required: false,
          phone_confirm_required: false,
          trust_required: false,
          tor_denied: false,
          bot: {
            enable: false,
            price_limit: null,
          },
          auto_price: {
            enabled: false,
            source: null,
            offset: null,
          },
        }
      },
      reset() {
        // TODO: clear
        this.form = this.defaultValues();
      },
      confirm(ad, labels) {
        this.form = ad;
      },
      publish(ad) {
        this.form = ad;
      },
      changeAd(status) {
        this.isChangeAd = status;
      },
    },
  }
</script>

<style lang="scss" scoped>
.main-content.kyc-no-confirmed-message {
  padding-bottom: 1rem;
}

.tablinks:hover {
  cursor: default;
}
</style>
